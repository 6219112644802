<template>
  <div class="viewContainer">
    <v-container>
      <v-row>
        <v-col
          align-self="center"
          xs="12"
          offset-xs="0"
          sm="10"
          offset-sm="1"
          md="8"
          offset-md="2"
          lg="6"
          offset-lg="3"
          xl="4"
          offset-xl="4"
        >
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="4">
                <v-btn :href="$init.config.schemeAndHost + '/p/' + pageSlug" target="_blank" large rounded block color="primary" class="no-caps mb-3"><v-icon class="mr-1" size="16">mdi-open-in-new</v-icon> {{ $t('preview') }}</v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn @click="copyStringToClipboard($init.config.schemeAndHost + '/p/' + pageSlug); tempUpdateCopyUrlBtn()" large rounded outlined block color="primary" class="no-caps mb-3"><v-icon class="mr-1" size="16">mdi-content-copy</v-icon> <span v-html="copyUrlBtnText"/></v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn @click="showPageSlugDialog()" large rounded outlined block color="primary" class="no-caps mb-3"><v-icon class="mr-1" size="16">mdi-pencil</v-icon> {{ $t('edit_url') }}</v-btn>
              </v-col>
            </v-row>
          </v-container>

          <v-card elevation="14" :outlined="false">
            <v-hover>
              <template v-slot:default="{ hover }">
                <div>
                  <v-img
                    :height="($auth.user().cover == null) ? 76 : 260"
                    :src="($auth.user().cover == null) ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=' : $auth.user().cover"
                  >
                  </v-img>
                  <v-card elevation="0" tile style="margin-top: -76px" color="transparent">
                    <v-row
                      align="end"
                      class="fill-height"
                    >
                      <v-col
                        align-self="start"
                        cols="12"
                      >
                        <v-avatar
                          class="ml-3"
                          color="grey"
                          size="128"
                        >
                          <v-img :src="$auth.user().avatar"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col class="py-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="title" v-html="$auth.user().name"></v-list-item-title>
                            <v-list-item-subtitle v-html="$auth.user().bio"></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-fade-transition>
                      <v-overlay
                        v-if="hover"
                        absolute
                        :style="{'margin-top' : ($auth.user().cover == null) ? '0' : '-184px'}"
                      >
                        <v-btn :to="{ name: 'user.profile' }" color="primary" rounded><v-icon class="mr-2" size="14">mdi-pencil</v-icon> {{ $t('edit_profile') }}</v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-card>
                </div>
              </template>
            </v-hover>

            <v-divider/>
            <v-card-actions class="pa-4">
              <v-btn color="primary" x-large rounded block @click="linkAction('new')"><v-icon class="mr-2">mdi-plus</v-icon> {{ $t('add_new_link') }}</v-btn>
            </v-card-actions>
            <v-divider/>

            <v-container v-if="pageLinks !== null && Array.isArray(pageLinks) && pageLinks.length > 0">
              <v-row dense v-for="(link, i) in pageLinks" :key="i">
                <v-col cols="1">
                  <div class="text-center d-flex align-start flex-column fill-height pa-1">
                    <div class="align-start flex-column">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn color="grey" x-small icon v-on="on" :disabled="link.first" @click="linkAction('moveUp', link.uuid, pageLinks[i-1].uuid)"><v-icon>mdi-arrow-up</v-icon></v-btn>
                        </template>
                        <span>{{ $t('move_up') }}</span>
                      </v-tooltip>
                    </div>
                    <div class="align-end flex-column mt-auto">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn color="grey" x-small icon v-on="on" :disabled="link.last" @click="linkAction('moveDown', link.uuid, pageLinks[i+1].uuid)"><v-icon>mdi-arrow-down</v-icon></v-btn>
                        </template>
                        <span>{{ $t('move_down') }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-col>
                <v-col cols="10">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :color="link.color"
                      :dark="!isBackgroundLight(link.color)"
                      :elevation="hover ? 12 : 2"
                      :href="link.url"
                      target="_blank"
                    >
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-title class="headline" v-html="link.title"/>
                          <v-card-text style="margin-top:-16px" v-html="(link.description !== null) ? link.description.replace(/(?:\r\n|\r|\n)/g, '<br />') : null"/>
                        </div>
                      </div>
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                        </v-overlay>
                      </v-fade-transition>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col cols="1">
                  <div class="text-center d-flex align-start flex-column fill-height pa-1">
                    <div class="align-start flex-column">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn color="primary" x-small icon v-on="on" @click="linkAction('edit', link.uuid, null, link)"><v-icon>mdi-pencil</v-icon></v-btn>
                        </template>
                        <span>{{ $t('edit_link') }}</span>
                      </v-tooltip>
                    </div>
                    <div class="align-end flex-column mt-auto">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn color="red" x-small icon v-on="on" @click="linkAction('delete', link.uuid)"><v-icon>mdi-delete</v-icon></v-btn>
                        </template>
                        <span>{{ $t('delete_link') }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-overlay
              v-if="pageLinks === null || pageLinksLoading"
              absolute
            >
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogCard" persistent max-width="600px" :key="key">
      <ValidationObserver ref="form1" v-slot="{ invalid }">
        <v-form 
          :model="form1" 
          @submit.prevent="submit('form1')"
          autocomplete="off"
          method="post"
        >
          <input type="hidden" name="uuid" v-model="form1.uuid">
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t('link') }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="8" class="py-0 my-0">
                    <x-text-field 
                      v-model="form1.title"
                      ref="form1.title"
                      id="form1.title"
                      counter="48"
                      :label="$t('title')"
                      rules="required|max:48"
                    />
                  </v-col>
                  <v-col cols="4" class="py-0 my-0">
                    <x-color-picker 
                      v-model="form1.color"
                      ref="form1.color"
                      id="form1.color"
                      :label="$t('color')"
                      rules="required"
                    />
                  </v-col>
                  <v-col cols="12" class="py-0 my-0">
                    <x-text-area
                      v-model="form1.description"
                      ref="form1.description"
                      id="form1.description"
                      counter="300"
                      :label="$t('description') + $t('_optional_')"
                      :name="$t('description')"
                      rules="nullable|max:300"
                      rows="2"
                    />
                    <x-text-field 
                      v-model="form1.url"
                      ref="form1.url"
                      id="form1.url"
                      :label="$t('link_url')"
                      rules="required|max:250"
                      placeholder="https://"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text :disabled="form1.loading || invalid" :loading="form1.loading" type="submit">{{ $t('save') }}</v-btn>
              <v-btn color="grey" text @click="dialogCard = false" :disabled="form1.loading">{{ $t('cancel') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>

    <v-dialog v-model="dialogSlug" persistent max-width="600px">
      <ValidationObserver ref="form2" v-slot="{ invalid }">
        <v-form 
          :model="form2" 
          @submit.prevent="submit('form2')"
          autocomplete="off"
          method="post"
        >
          <input type="hidden" name="uuid" v-model="form1.uuid">
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t('edit_url') }}</span>
            </v-card-title>
            <v-card-text>
              <x-text-field 
                v-model="form2.slug"
                @keypress="validateSlug"
                ref="form2.slug"
                id="form2.slug"
                counter="32"
                :name="$t('page_url')"
                single-line
                rules="required|min:5|max:32"
                :prefix="$init.config.schemeAndHost + '/p/'"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text :disabled="form2.loading || invalid" :loading="form2.loading" type="submit">{{ $t('save') }}</v-btn>
              <v-btn color="grey" text @click="dialogSlug = false" :disabled="form2.loading">{{ $t('cancel') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>
  </div>
</template>
<script>
import { copyStringToClipboard, isBackgroundLight } from '@/utils/helpers';

export default {
  data: () => ({
    locale: 'en',
    key: 0,
    dialogCard: false,
    dialogSlug: false,
    pageSlug: '',
    pageLinksLoading: false,
    copyUrlBtnText: '',
    form1: {
      uuid: '',
      title: '',
      color: '',
      description: '',
      url: '',
      loading: false
    },
    form2: {
      slug: '',
      loading: false
    },
    pageLinks: null
  }),
  created () {
    // Set default values
    this.copyUrlBtnText = this.$t('copy_url')
    this.form2.slug = this.$auth.user().slug
    this.pageSlug = this.$auth.user().slug

    // Show left drawer
    if (this.$vuetify.breakpoint.mdAndUp) this.$store.dispatch('setDashboardDrawer', true)

    // Set locale
    let locale = Intl.DateTimeFormat().resolvedOptions().locale || 'en'
    locale = (this.$auth.check()) ? this.$auth.user().locale : locale
    this.locale = locale

    this.moment.locale(this.locale.substr(0,2))

    this.getPageLinks()
  },
  methods: {
    copyStringToClipboard,
    isBackgroundLight,
    validateSlug (e) {
      let char = String.fromCharCode(e.keyCode)
      //console.log(e.keyCode)
      //console.log(char)
      if (e.shiftKey) {
        e.preventDefault()
      }
      if (e.keyCode == 32) {
        e.preventDefault()
        this.form2.slug += '-' 
      }
      if (e.keyCode !== 8) {
        let test = /[a-z0-9-]/g.test(char)
        if (!test) {
          e.preventDefault()
        }
      }
    },
    tempUpdateCopyUrlBtn () {
      this.copyUrlBtnText = this.$t('copied')
      setTimeout (
        () => this.copyUrlBtnText = this.$t('copy_url'), 
      2000)
    },
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    showPageSlugDialog () {
      this.form2.slug = this.pageSlug
      this.dialogSlug = true
    },
    linkAction (action, uuid = null, uuid2 = null, link = null) {
      if (action == 'moveUp') {
        this.changeOrder(uuid, uuid2)
        return
      }

      if (action == 'moveDown') {
        this.changeOrder(uuid, uuid2)
        return
      }

      if (action == 'new') {
        this.form1 = {
          uuid: '',
          title: '',
          color: '',
          description: '',
          url: '',
          loading: false
        }
        this.key++
        this.dialogCard = true
        return
      }

      if (action == 'edit') {
        this.form1 = {
          uuid: link.uuid,
          title: link.title,
          color: link.color,
          description: link.description,
          url: link.url,
          loading: false
        }
        this.key++
        this.dialogCard = true
        return
      }

      if (action == 'delete') {
        this.$root.$confirm(this.$t('delete'), this.$t('confirm_delete_link'))
        .then((confirm) => {
          if (confirm) {
            this.pageLinksLoading = true

            this.axios.post('/user/page-link/delete', {
                locale: this.$i18n.locale,
                uuid: uuid
              })
            .then(res => {
              if (res.data.status === 'success') {
                this.getPageLinks()
                this.$root.$snackbar(res.data.msg, {timeout: 2000})
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg, {timeout: 8000})
              }
              this.pageLinksLoading = false
            })
          }
        })
      }
    },
    getPageLinks () {
      this.axios
        .get('/user/page-links', { params: { locale: this.$i18n.locale }})
        .then(response => {
          this.pageLinks = response.data
          this.pageLinksLoading = false
        })
      
    },
    changeOrder (uuid1, uuid2) {
      this.pageLinksLoading = true
      this.axios.post('/user/page-links/change-order', {
          locale: this.$i18n.locale,
          uuid1: uuid1,
          uuid2: uuid2
        })
      .then(res => {
        if (res.data.status === 'success') {
          this.getPageLinks()
          this.$root.$snackbar(res.data.msg, {timeout: 2000})
        }
      })
      .catch(err => {
        if (err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg, {timeout: 8000})
        }
        this.pageLinksLoading = false
      })
    },
    async submit (formName) {
      // Reset form validation
      this.$refs[formName].reset()

      // Form defaults
      let form = this[formName]
      form.loading = true

      let postUrl = '/user/page-link/save'
      if (formName == 'form2') postUrl = '/user/page-url/save'

      this.axios
        .post(postUrl, {
          locale: this.$i18n.locale,
          form: form
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.$root.$snackbar(res.data.msg, {timeout: 2000})
            if (formName == 'form1') {
              this.getPageLinks()
              this.dialogCard = false
            } else if (formName == 'form2') {
              this.pageSlug = this.form2.slug
              this.$auth.user().slug = this.form2.slug
              this.dialogSlug = false
            }
          }
          form.loading = false
        })
        .catch(error => {
          if (typeof error.response.data.msg !== 'undefined') {
            this.$root.$snackbar(error.response.data.msg, {timeout: 8000})
            if (formName == 'form1') {
              this.dialogCard = false
            } else if (formName == 'form2') {
              this.dialogSlug = false
            }
          }

          let errors = error.response.data.errors || []

          for (let field in errors) {
            this.$refs[formName + '.' + field].applyResult({
              errors: errors[field],
              valid: false,
              failedRules: {}
            })
          }

          form.loading = false
        })
    }
  }
}
</script>